import { useStore, useCmsModule, useRouter } from '@fwk-client/utils/vue-3-migration';
import { types as layoutTypes } from '@root/src/client/store/layout';
import { types as componentsTypes } from '@fwk-client/modules/cms/stores/components';
import { anchorLink } from '../theme/canvas-664/canvas.utils';
import { CmsLabel } from '@fwk-client/modules/cms/types';

/** @cmsOptions */
export interface ActionOptions {
  pageName?:string, // A page name to target in application
  contactIndex?:number, // A contact index to target with contact modal
  path?:string|any, // An absolute path (which can be localizedText structure)
  anchor?:string,
}

/** @cmsLabels */
export interface ActionLabels {
  /** @cmsType CmsLabel */
  contactModalDescription?:CmsLabel;
}

export function useActions() {
  const router = useRouter();
  const store = useStore(); 
  const cms = useCmsModule(); 

  const onActionClick = (evt:Event, actionOptions:ActionOptions, actionLabels?:ActionLabels) => {
    if(actionOptions.pageName) {
        evt.preventDefault();
        router.push({name: actionOptions.pageName}).catch(() => {});
    }
    else if(actionOptions.anchor) {
        evt.preventDefault();
        anchorLink(actionOptions.anchor, 60);
    }
    else if(actionOptions.contactIndex != undefined) {
        evt.preventDefault();
  
        // We update the store for component properties               
        store.commit('cms/components/' + componentsTypes.mutations.SET_COMPONENT_PROP, { 
            componentPath : "canvas/ContactModal",
            propName : 'contactIndex',
            propValue : actionOptions.contactIndex 
        })
  
        // We set the custom description if available
        if(actionLabels && actionLabels.contactModalDescription) {
            store.commit('cms/components/' + componentsTypes.mutations.SET_COMPONENT_PROP, { 
                componentPath : "canvas/ContactModal",
                propName : 'description',
                propValue : cms.utils.getComputedLabel(actionLabels.contactModalDescription)
            })
        }
  
        store.commit('layout/'+ layoutTypes.mutations.SHOW_CONTACT_FORM_SWITCH);
    }
  }

  const actionHref = (actionOptions:ActionOptions) => {
    if(actionOptions.pageName) {
        var resolve = router.resolve({name: actionOptions.pageName});
        return resolve.href;
    }
    else if(actionOptions.path) {
        return cms.utils.getComputedLabel(actionOptions.path);
    }
    else if(actionOptions.anchor) {
        return actionOptions.anchor
    }
    return 'javascript:void(0)';
  }

  const actionTarget = (actionOptions:ActionOptions) => {
    if(actionOptions.path) {
        return '_blank'
    }
    return undefined;
  }

  return {
    onActionClick,
    actionHref,
    actionTarget
  }
}