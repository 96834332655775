<template>
    <div class="teaser" ref="teaser">
        <div class="container clearfix">
            <div class="line"></div>
        </div>
        <div class="container clearfix">
            <div class="row col-mb-50 mb-0">

                <div class="col-md-5">

                    <div class="heading-block">
                        <h2>{{ $t('home.teaser.title') }}</h2>
                        <span>{{ $t('home.teaser.sub-title') }}</span>
                    </div>

                    <p>{{ $t('home.teaser.text') }}</p>

                    <a href="javascript:void(0)" @click="onTeaserButtonClick" class="button button-border button-rounded button-large noleftmargin topmargin-sm">{{ $t('home.teaser.button') }}</a>

                </div>

                <div class="col-md-7">
                    <img data-animate="fadeInRightBig" src="@public/img/vigneron-online/home/imac.png" alt="Imac">
                </div>
            </div>

        </div>
    </div>
</template>

<style>
    .teaser .col-md-7 {
        text-align: right;
    }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { languagesTypes } from '@fwk-client/store/types';
import { animateElements, removeElementsAnimation } from '../../../theme/canvas-664/canvas.animations';

@Component({
  components: { 
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class Teaser extends Vue {

    mounted() {
        animateElements(this.$refs.teaser as HTMLElement)
    }

    beforeDestroy() {
        removeElementsAnimation(this.$refs.teaser as HTMLElement)
    }

    onTeaserButtonClick() {
        // We display confirmation page
		var languageCode = this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
      	// We redirect to the confirmation page
      	this.$router.push({
            name : 'nos-services',
            params : {
                lang : languageCode,
            }
		}).catch(err => {});
	}
    
}
</script>