<template>
    <div class="video">
        <div class="container clearfix">
            <div class="line"></div>
        </div>
        <div class="container clearfix">
          <div class="row col-mb-50 mb-0">

            <div class="col-md-7">
                <div class="iframe-container">
                    <iframe class="responsive-iframe" src="https://www.youtube.com/embed/gvJ4I6n6_R4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            <div class="col-md-5">

                <div class="heading-block">
                    <h2>{{ $t('home.video.title') }}</h2>
                    <span>{{ $t('home.video.sub-title') }}</span>
                </div>

                <a href="javascript:void(0)" @click="showContactModal" class="button button-border button-rounded button-large noleftmargin topmargin-sm">{{ $t('home.video.button') }}</a>

            </div>

          </div>

        </div>
    </div>
</template>

<style>
.video .iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 57%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.video .responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* device-lg */
@media (min-width: 1200px) {
  .video .iframe-container {
    margin-top:0px;
  }
}

/* device-md */
@media (min-width: 992px) and (max-width: 1199px) {
  .video .iframe-container {
    margin-top:40px;
  }
}

/* device-sm */
@media (min-width: 768px) and (max-width: 991px) {
  .video .iframe-container {
    margin-top:80px;
  }
}
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { types as layoutTypes } from '../../../store/layout';

@Component({
  components: { 
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class Video extends Vue {

    showContactModal() {
        this.$store.commit('layout/'+ layoutTypes.mutations.SHOW_CONTACT_FORM_SWITCH);
    }
    
}
</script>