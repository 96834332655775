



<template>

    <Section :options="sectionOptions" id="latest-news" class="latest-news pb-0" v-if="actualites.length > 0">

        <div class="heading-block center"><h2>{{ $cms.utils.getComputedLabel(labels.title) }}</h2></div>

          <div class="row posts-md col-mb-30">

						<div :class="postCssClass" v-for="(actualite, index) in actualites" :key="index + '-' + currentLanguageCode">
							<div class="entry">
                <div v-if="actualites[index].picture" class="entry-image">
                  <router-link :to="getPostPath(actualites[index])">
                    <img :src="getStaticURLFromPath(actualites[index].picture.original.path)" :alt="getLocalizedText(actualites[index].title)">
                  </router-link>
                </div>
								<div class="entry-title title-xs nott">
									<h3>
                    <router-link :to="getPostPath(actualites[index])">
                      {{ getLocalizedText(actualites[index].title) }}
                    </router-link>
                  </h3>
								</div>
								<div class="entry-meta">
									<ul>
										<li><i class="icon-calendar3"></i> {{ formatDate(actualites[index].date) }}</li>
                    <li v-if="false" ><a href="blog-single.html#comments"><i class="icon-comments"></i> 13</a></li>
                    <li v-if="false"><a href="#"><i class="icon-camera-retro"></i></a></li>
									</ul>
								</div>
								<div class="entry-content">
                  <p>{{ getLocalizedText(actualites[index].shortDescription) }}</p>
								</div>
							</div>
						</div>

					</div>

    </Section>
    
</template>

<style>

    .latest-news .entry-image img {
      max-height: 200px;
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { formatDay, FormatDateType, formatMonth as genericFormatMonth } from '@igotweb-node-api-utils/formatter';
import { languagesTypes } from '@fwk-client/store/types';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getters as blogGetters } from '@fwk-client/modules/blog/stores';

/** @cmsOptions */
export interface LatestNewsOptions {
  /** @cmsType CmsText */
  feedName?:CmsText,
  /** @cmsType CmsText */
  actualitePageName?:CmsText,
  /** @cmsType CmsInterface */
  sectionOptions?:SectionOptions
}

/** @cmsLabels */
export interface LatestNewsLabels {
   /** @cmsType CmsLabel */
  title?:CmsLabel;
}

/** @cmsSlots */
export interface LatestNewsSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<LatestNewsOptions>,
        labels: {
          type: Object as PropType<LatestNewsLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<LatestNewsSlots>
    },
    components: {
        Section
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const store = useStore();

        const computedOptions:LatestNewsOptions = {
            sectionOptions : {section:false, container:true},
            actualitePageName:"actualite",
            ...props.options
        };

        const labels:LatestNewsLabels = {
            title: app.$t('actualites.latest.title') ,
            ...props.labels
        }

		const actualites:Ref<any[]> = ref([]);
		if(computedOptions.feedName && computedOptions.feedName != "") {
      // We try to get the posts from store is available
      var posts = blogGetters.$blog_posts.get.call(app).call(app, computedOptions.feedName);
      // We check if we have posts
      if(posts.length == 0) {
        // We try to load the feed from DB
        app.$blog.feed.getFeed(computedOptions.feedName).then(() => {
          actualites.value = [
            ...blogGetters.$blog_posts.get.call(app).call(app, computedOptions.feedName)
          ].slice(0, 4);;
        });
      }
      else {
        actualites.value = [
          ...posts
        ].slice(0, 4);
      }
      
    }

		const currentLanguageCode = computed(() => {
			return store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
		})

    const postCssClass = computed(() => {
      switch(actualites.value.length) {
        case 1: {
          return "col-lg-12 col-md-12"
        }
        case 2: {
          return "col-lg-6 col-md-6"
        }
        case 3: {
          return "col-lg-4 col-md-6"
        }
        default: {
          return "col-lg-3 col-md-6";
        }
      }
    })

		const formatDate = (date:Date) => {
			return formatDay(date, currentLanguageCode.value)
		}

		const getPostPath = (post:any) => {
			// We display product details page
			var languageCode = currentLanguageCode.value;
			return {
				name : computedOptions.actualitePageName,
				params : {
					lang : languageCode,
					postPath : post.path
				}
			}
		}

    return {
      sectionOptions:computedOptions.sectionOptions,
      actualites,
      currentLanguageCode,
      formatDate,
      getPostPath,
      postCssClass,
      labels
    }

    }
})
</script>