<template>
    <div class="demo-shop" ref="demoShop">
        <div class="container clearfix">
            <div class="line"></div>
        </div>
        <div class="container clearfix">
          <div class="row col-mb-50 mb-0">

            <div class="col-md-7">
                <img data-animate="fadeInLeftBig" src="@public/img/vigneron-online/home/demo-shop.png" alt="Imac">
            </div>

            <div class="col-md-5">

                <div class="heading-block">
                    <h2>{{ $t('home.demo-shop.title') }}</h2>
                    <span>{{ $t('home.demo-shop.sub-title') }}</span>
                </div>

                <p>{{ $t('home.demo-shop.text') }}</p>

                <a href="javascript:void(0)" @click="onButtonClick" class="button button-border button-rounded button-large noleftmargin topmargin-sm">{{ $t('home.demo-shop.button') }}</a>

            </div>
          </div>
        

        </div>
    </div>
</template>

<style>

/* device-lg */
@media (min-width: 1200px) {
  .demo-shop img {
    margin-top:10px;
  }
}

/* device-md */
@media (min-width: 992px) and (max-width: 1199px) {
  .demo-shop img {
    margin-top:35px;
  }
}

/* device-sm */
@media (min-width: 768px) and (max-width: 991px) {
  .demo-shop img {
    margin-top:60px;
  }
}
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { languagesTypes } from '@fwk-client/store/types';
import { animateElements, removeElementsAnimation } from '../../../theme/canvas-664/canvas.animations';

@Component({
  components: { 
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class DemoShop extends Vue {

  mounted() {
        animateElements(this.$refs.demoShop as HTMLElement)
    }

    beforeDestroy() {
        removeElementsAnimation(this.$refs.demoShop as HTMLElement)
    }

    onButtonClick() {
        // We display confirmation page
		    var languageCode = this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
      	// We redirect to the confirmation page
      	return this.$router.push({
            name : 'shop-products',
            params : {
                lang : languageCode,
                shopPath : 'domaine-igot-web-demo'
            }
		    }, () => {})
	}
    
}
</script>