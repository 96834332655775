<template>
    <Section :options="computedOptions.sectionOptions">

        <div :class="(isStyleInline ? 'row mb-0 inline' : '')" ref="contactBanner">

            <div v-if="computedOptions.style == 'inline-left' && components.buttons" class="col-md-auto">
                <CmsComponent v-for="(component, index) in components.buttons" :key="index+'_top'" :component="component"></CmsComponent>
            </div>

            <div v-if="labels.h2 || labels.text" :class="(isStyleInline ? 'col-md-auto heading-block mb-0 border-bottom-0' : 'heading-block text-center border-bottom-0')">
                <h2 v-if="labels.h2">{{ $cms.utils.getComputedLabel(labels.h2) }}</h2>
                <LocalizedContent v-if="labels.text" tag="span" :localizedContent="labels.text"></LocalizedContent>
            </div>

            <div v-if="(computedOptions.style == 'inline-right' || computedOptions.style == 'heading-block') && components.buttons" :class="(isStyleInline ? 'col-md-4' : 'center contact-buttons')">
                <CmsComponent v-for="(component, index) in components.buttons" :key="index+'_bottom'" :component="component"></CmsComponent>
            </div>
        
        </div>

    </Section>
</template>

<style>

.row.inline {
    justify-content: center;
}

@media (max-width: 575.98px) {
    .contact-buttons a.button {
        display: block;
        padding:9px 16px;
    }
}

@media (max-width: 399.98px) {
    .contact-buttons a.button {
        font-size: 14px;
    }
}
</style>

<script lang="ts">
import Section, {SectionOptions} from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';
import { CmsContent, CmsEnum, CmsLabel } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import { Components } from '@fwk-client/modules/cms/helpers/components';

/** @cmsOptions */
export interface ContactBannerOptions {
    /** @cmsType CmsEnum */
    style?:CmsEnum<"heading-block"|"inline-left"|"inline-right">,
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions
}

/** @cmsLabels */
export interface ContactBannerLabels {
    /** @cmsType CmsLabel */
  h2?:CmsLabel;
   /** @cmsType CmsContent */
  text?:CmsContent;
}

/** @cmsSlots */
export interface ContactBannerSlots {
    buttons?:Components[]
}


export default defineComponent({
    props: {
        options: Object as PropType<ContactBannerOptions>,
        labels: {
          type: Object as PropType<ContactBannerLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<ContactBannerSlots>
    },
    components : {
        Section,
        LocalizedContent
    },
    setup(props, context) {

        const computedOptions:ContactBannerOptions = {
            style : "heading-block",
            ...props.options
        };

        const isStyleInline = computedOptions.style == "inline-left" || computedOptions.style == "inline-right";

        return {
            computedOptions: {
                ...computedOptions
            },
            isStyleInline
        }

    }
})
</script>