var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home content"},[(_vm.displayBackToShop && _vm.$shop_shop)?_c('div',{staticClass:"section back-to-shop"},[_c('div',{staticClass:"container clearfix"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.getStaticURLFromPath(_vm.$shopContent_logo.original.path),"alt":_vm.$shop_shop.name}})])]),_vm._v(" "),_c('div',{staticClass:"col-9"},[_c('h3',[_vm._v(_vm._s(_vm.$shop_shop.name))]),_vm._v(" "),_c('a',{staticClass:"button button-border button-rounded button-large noleftmargin",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.onBackToShopButtonClick}},[_vm._v(_vm._s(_vm.$t('home.back-to-shop.button')))])])])])]):_vm._e(),_vm._v(" "),_c('RowContent',{staticClass:"intro",attrs:{"labels":{
				h1 : _vm.$t('home.intro.title'),
				h2 : _vm.$t('home.intro.sub-title'),
				pictureAlt : 'Vigneron-Online'
			},"options":{
				isAnimated : true,
				style : 'heading',
				pictureURL : '@public/img/vigneron-online/home/intro.png'
			}}}),_vm._v(" "),_c('Services'),_vm._v(" "),_c('div',{staticClass:"latest-news-home"},[_c('LatestNews',{attrs:{"options":{feedName:'vigneron-online'}}})],1),_vm._v(" "),_c('ContactBanner',{staticClass:"contact-banner",attrs:{"labels":{
				h2 : {
					fr: 'Contactez-nous',
					en: 'Contact us',
					de: 'Kontaktiere uns'
				},
				text: {
					fr: 'Vous souhaitez en savoir un peu plus? Nous parler de votre projet ?<br/>Nos consultation sont <b>gratuites et sans engagement. <i class=\'icon-line-circle-check\'></i></b>',
					en: 'You would like to know more ? Discuss your project ?<br/>Our consultations are <b>free with no engagement. <i class=\'icon-line-circle-check\'></i></b>',
					de: 'Willst du ein bisschen mehr wissen? Erzählen Sie uns von Ihrem Projekt?<br/>Unsere Beratungen sind <b>kostenlos und unverbindlich. <i class=\'icon-line-circle-check\'></i></b>'
				} 
			},"components":{
          		buttons : [
            		{
              			path: 'canvas/actions/Button',
              			props: {
							class: 'button-3d button-xlarge',
                			labels: {
                  				button : {
                    				fr: 'Contactez-nous',
									en: 'Contact us',
									de: 'Kontaktiere uns'
                  				}
                			},
                			options: {
								action : {
                  					contactIndex : 0
								}
                			}
              			}
            		},
					{
              			path: 'canvas/actions/Button',
              			props: {
							class: 'button-3d button-xlarge',
                			labels: {
                  				button : {
                    				fr: 'Prendre rendez-vous',
									en: 'Schedule an appointment',
									de: 'Einen Termin vereinbaren'
                  				}
                			},
                			options: {
								action :{
                  					path : 'https://calendly.com/nicolas-igot/30min'
								}
                			}
              			}
            		}
          		]
        	}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }