<template>
    <div class="row-content" ref="rowContent">
        <div v-if="computedOptions.showTopLine" class="container clearfix">
            <div class="line"></div>
        </div>
        <Section :options="computedOptions.sectionOptions">
            <div :class="'row mb-0' + (computedOptions.showTopLine ? ' mt-5':'')">
                <div v-if="computedOptions.pictureURL && computedOptions.picturePosition == 'left'" class="col-md-4 picture">
                    <img :data-animate="computedOptions.isAnimated ? 'fadeInLeftBig':undefined" :src="computedOptions.pictureURL" :alt="$cms.utils.getComputedLabel(labels.pictureAlt)" />
                </div>
                
                <div :class="computedOptions.pictureURL ? 'col-md-8':'col-md-12'">

                    <div :class="headingCssClass">
                        <span class="heading-title" v-if="labels.headingTitle">{{ $cms.utils.getComputedLabel(labels.headingTitle) }}</span>
                        <h1 v-if="labels.h1">{{ $cms.utils.getComputedLabel(labels.h1) }}</h1>
                        <h2 v-if="labels.h2">{{ $cms.utils.getComputedLabel(labels.h2) }}</h2>
                        <h3 v-if="labels.h3">{{ $cms.utils.getComputedLabel(labels.h3) }}</h3>
                        <span v-if="labels.subTitle">{{ $cms.utils.getComputedLabel(labels.subTitle) }}</span>

                        <div v-if="computedOptions.style == 'heading'">
                            <LocalizedContent v-if="labels.text" tag="div" class="text" :localizedContent="labels.text"></LocalizedContent>
                            <CmsComponent v-for="(component, index) in components.buttons" :key="index+'_buttons'" :component="component"></CmsComponent>
                            <!-- <router-link v-if="computedOptions.buttonPath && labels.button" :to="computedOptions.buttonPath" class="button button-border button-rounded button-large m-0">{{ $cms.utils.getComputedLabel(labels.button) }}</router-link> -->
                        </div>
                    </div>

                    <div v-if="computedOptions.style == 'heading-block'">
                        <LocalizedContent v-if="labels.text" tag="div" class="text" :localizedContent="labels.text"></LocalizedContent>
                        <CmsComponent v-for="(component, index) in components.buttons" :key="index+'_buttons'" :component="component"></CmsComponent>
                        <!-- <router-link v-if="computedOptions.buttonPath && labels.button" :to="computedOptions.buttonPath" class="button button-border button-rounded button-large m-0">{{ $cms.utils.getComputedLabel(labels.button) }}</router-link> -->
                    </div>
                    
                </div>

                <div v-if="computedOptions.pictureURL && computedOptions.picturePosition == 'right'" class="col-md-4 picture">
                    <img :data-animate="computedOptions.isAnimated ? 'fadeInRightBig':undefined" :src="computedOptions.pictureURL" :alt="$cms.utils.getComputedLabel(labels.pictureAlt)">
                </div>
            </div>

        </Section>
    </div>
</template>

<style>
    .row-content .heading-block {
        margin-bottom: 20px;
    }

    .row-content .heading-block span {
        margin-bottom: 20px;
    }

    .row-content .heading-block:after {
        margin-top: 10px;
    }

    .row-content .heading {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }

    .row-content .picture {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .row-content img {
        max-height: 400px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .row-content .heading .heading-title {
        font-size:60px;
        font-weight: 600;
        margin-bottom: 20px;
        line-height: 46px;
        color: var(--secondary-color);
    }

    .row-content .heading h1 {
        font-size:39px;
        margin-top:0px;
        margin-bottom: 10px;
        line-height: 46px;
        color: #333;
    }

    .row-content .heading h2 {
        font-weight: 300;
        font-size: 1.25rem;
    }

    .row-content div.text {
        font-size: 1.25rem;
        font-weight: 300;
        margin-bottom: 30px;
    }

    .row-content div.text p {
        margin-bottom: 10px;
    }

    .row-content div.text ul {
        list-style-type: none;
        margin-left:10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .row-content div.text ul ul {
        margin-left:20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .row-content div.text ul li:not(:first-child) {
        margin-top: 5px;
    }

    .row-content div.text ul li:not(:last-child) {
        padding-bottom: 5px;
    }

    .row-content div.text ul li:before {
        font-family: "font-icons";
        content: "\e77d"; /* "\e6c8"; "\e71e" */
        color: var(--secondary-color);
        margin-right: 10px;
    }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { animateElements, removeElementsAnimation } from '@root/src/client/theme/canvas-664/canvas.animations';
import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum, CmsPicture } from '@fwk-client/modules/cms/types';


/** @cmsOptions */
export interface RowContentOptions {
    /** @cmsType CmsInterface */
  sectionOptions?:SectionOptions
    /** @cmsType CmsBoolean */
  isAnimated?:CmsBoolean,
  /** @cmsType CmsBoolean */
  showTopLine?:CmsBoolean,
  /** @cmsType CmsPicture */
  pictureURL?:CmsPicture,
  /** @cmsType CmsEnum */
  picturePosition?:CmsEnum<"left"|"right">,
  /** @cmsType CmsEnum */
  style?:CmsEnum<"heading"|"heading-block">,
  /** @cmsType CmsBoolean */
  textCenter?:CmsBoolean;
  buttonPath?: string; // To be removed
}

/** @cmsLabels */
export interface RowContentLabels {
    /** @cmsType CmsLabel */
  headingTitle?:CmsLabel;
  /** @cmsType CmsLabel */
  h1?:CmsLabel;
  /** @cmsType CmsLabel */
  h2?:CmsLabel;
  /** @cmsType CmsLabel */
  h3?:CmsLabel;
  /** @cmsType CmsLabel */
  subTitle?:CmsLabel;
  /** @cmsType CmsContent */
  text?:CmsContent;
  /** @cmsType CmsLabel */
  pictureAlt?:CmsLabel;
  /** @cmsType CmsLabel */
  button?:CmsLabel; // To be removed
}

/** @cmsSlots */
export interface RowContentSlots {
    buttons?:any[]
}

@Component({
  components: { 
      LocalizedContent,
      Section
  },
  // We add name property from store as a name computed
  computed: {
  }
})
export default class RowContent extends Vue {

    @Prop({
        type: Object,
        required: false,
        default: () => { return {} }
    }) readonly components!: RowContentSlots

    @Prop({
        type: Object,
        required: false,
    }) readonly options!: RowContentOptions | undefined

    computedOptions:RowContentOptions = {
        isAnimated : false,
        showTopLine : false,
        picturePosition : "right",
        style: "heading-block",
        textCenter : false,
        sectionOptions : {
            section : false
        },
        ...this.options,
        pictureURL : (this.options && this.options.pictureURL) ? this.$cms.utils.getComputedPictureURL(this.options.pictureURL) : undefined
    };

    @Prop({
        type: Object,
        required: false,
        default: () => { return {} }
    }) readonly labels!: RowContentLabels

    get headingCssClass() {
        var cssClass = this.computedOptions.style;
        if(this.computedOptions.textCenter) {
            cssClass += " center";
        }
        return cssClass
    }

    mounted() {
        if(this.computedOptions.isAnimated) {
            animateElements(this.$refs.rowContent as HTMLElement)
        }
    }

    beforeDestroy() {
        if(this.computedOptions.isAnimated) {
            removeElementsAnimation(this.$refs.rowContent as HTMLElement)
        }
    }
    
}
</script>