<template>
	<div class="home content">
		<div v-if="displayBackToShop && $shop_shop" class="section back-to-shop">
			<div class="container clearfix">
				<div class="row">

					<div class="col-3">
						<div class="logo">
							<img :src="getStaticURLFromPath($shopContent_logo.original.path)" :alt="$shop_shop.name">
						</div>
					</div>

					<div class="col-9">
						<h3>{{$shop_shop.name}}</h3>
						<a href="javascript:void(0)" @click="onBackToShopButtonClick" class="button button-border button-rounded button-large noleftmargin">{{ $t('home.back-to-shop.button') }}</a>
					</div>

				</div>
			</div>
		</div>

		<RowContent
			class="intro"
			:labels="{
				h1 : $t('home.intro.title'),
				h2 : $t('home.intro.sub-title'),
				pictureAlt : 'Vigneron-Online'
			}"
			:options="{
				isAnimated : true,
				style : 'heading',
				pictureURL : '@public/img/vigneron-online/home/intro.png'
			}"
		/>
		
		<Services />
		
		<!-- <Slider /> -->
		<div class="latest-news-home">	
			<LatestNews :options="{feedName:'vigneron-online'}" />
		</div>

		<ContactBanner 
			class="contact-banner"
			:labels="{
				h2 : {
					fr: 'Contactez-nous',
					en: 'Contact us',
					de: 'Kontaktiere uns'
				},
				text: {
					fr: 'Vous souhaitez en savoir un peu plus? Nous parler de votre projet ?<br/>Nos consultation sont <b>gratuites et sans engagement. <i class=\'icon-line-circle-check\'></i></b>',
					en: 'You would like to know more ? Discuss your project ?<br/>Our consultations are <b>free with no engagement. <i class=\'icon-line-circle-check\'></i></b>',
					de: 'Willst du ein bisschen mehr wissen? Erzählen Sie uns von Ihrem Projekt?<br/>Unsere Beratungen sind <b>kostenlos und unverbindlich. <i class=\'icon-line-circle-check\'></i></b>'
				} 
			}"
			:components="{
          		buttons : [
            		{
              			path: 'canvas/actions/Button',
              			props: {
							class: 'button-3d button-xlarge',
                			labels: {
                  				button : {
                    				fr: 'Contactez-nous',
									en: 'Contact us',
									de: 'Kontaktiere uns'
                  				}
                			},
                			options: {
								action : {
                  					contactIndex : 0
								}
                			}
              			}
            		},
					{
              			path: 'canvas/actions/Button',
              			props: {
							class: 'button-3d button-xlarge',
                			labels: {
                  				button : {
                    				fr: 'Prendre rendez-vous',
									en: 'Schedule an appointment',
									de: 'Einen Termin vereinbaren'
                  				}
                			},
                			options: {
								action :{
                  					path : 'https://calendly.com/nicolas-igot/30min'
								}
                			}
              			}
            		}
          		]
        	}"
		/>
		<!-- <Video /> -->
		<!-- <Teaser /> -->
		<!-- <DemoShop /> -->
	</div>
</template>

<style>

.home .back-to-shop {
	margin:0px;
	padding:30px;
}

@media (max-width: 575.98px) {
	.home .back-to-shop {
		margin:0px;
		padding:30px 0px;
	}
}

.home .back-to-shop .logo {
    height: 100px;
    width: 100px;
    padding: 5px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.075);
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
	margin-left:auto;
}

.home .back-to-shop h3 {
	margin-bottom:8px;
}

.latest-news-home {
	padding-top:30px;
	margin-bottom:30px;
}

.latest-news-home .entry-content p {
	font-weight:lighter;
}
.latest-news-home .entry {
	box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
	padding-bottom: 10px;
	height:100%;
}
.latest-news-home .entry > div {
	margin-left:10px;
	margin-right:10px;
}
.latest-news-home .entry > div.entry-image {
	margin:0px;
}

.video {
	background:#ffffff;
	padding-top:30px;
}

.video .line {
	margin:0px;
	margin-bottom:30px;
}

.teaser {
	background:#f9f9f9;
	padding-top:30px;
}

.teaser .line {
	display:none;
}

.demo-shop {
	background:#ffffff;
	padding-top:30px;
}

.demo-shop .line {
	display:none;
}

</style>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import RowContent from '../cms/canvas/RowContent.vue'

import ContactBanner from '../cms/canvas/ContactBanner.vue'
import Button from '../cms/canvas/actions/Button.vue'

import Services from '../panels/home/Services.vue'
import Teaser from '../panels/home/Teaser.vue'
import Video from '../panels/home/Video.vue'
import DemoShop from '../panels/home/DemoShop.vue'

import LatestNews from '../cms/canvas/blog/LatestNews.vue';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  components: {
	  RowContent,
	  ContactBanner,
	  Services,
	  Teaser,
	  Video,
	  DemoShop,
	  LatestNews,
	  Button
  }
})
export default class Home extends mixins(GenericPage) {

	displayBackToShop = false;

	mounted() {
		if(this.$shop_shop) {
			this.displayBackToShop = true;
		}
	}
	
	onBackToShopButtonClick() {
        // We display confirmation page
		var languageCode = this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
      	// We redirect to the confirmation page
      	this.$router.push({
            name : 'shop-products',
            params : {
                lang : languageCode,
                shopPath : this.$shopContent_path
            }
		}).catch(err => {});
    }

}
</script>