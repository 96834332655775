/*-------------------------------------------------------------------------------*/
/*	UPDATE PROCESS: Compare plugins.animations.js file between versions 		 */
/*	to adapt methods	 														 */
/*-------------------------------------------------------------------------------*/

const SELECTOR = '[data-animate]';
const ANIMATE_CLASS_NAME = 'animated';

var intersectionObserver:null|IntersectionObserver = null;
if(process.env.VUE_ENV == "client") {
	intersectionObserver = new IntersectionObserver(
		function(entries, observer) {
			entries.forEach((entry) => {
	
				let target				= entry.target,
					elAnimation				= target.getAttribute('data-animate'),
					elAnimOut				= target.getAttribute('data-animate-out'),
					elAnimDelay				= target.getAttribute('data-delay'),
					elAnimDelayOut			= target.getAttribute('data-delay-out'),
					elAnimDelayTime			= 0,
					elAnimDelayOutTime		= 3000;
	
				/*
				if( thisElement.parents('.fslider.no-thumbs-animate').length > 0 ) { return true; }
				if( thisElement.parents('.swiper-slide').length > 0 ) { return true; }
				*/
	
				if( elAnimDelay ) { elAnimDelayTime = Number( elAnimDelay ) + 500; } else { elAnimDelayTime = 0; }
				if( elAnimOut && elAnimDelayOut ) { elAnimDelayOutTime = Number( elAnimDelayOut ) + elAnimDelayTime; }
	
				if( !target.classList.contains('animated') ) {
					target.classList.add('not-animated');
					if (entry.intersectionRatio > 0) {
	
						setTimeout(function() {
							target.classList.remove('not-animated');
							target.classList.add(elAnimation as string,'animated');
						}, elAnimDelayTime);
	
						if( elAnimOut ) {
							setTimeout( function() {
								target.classList.remove( elAnimation + '' );
								target.classList.add( elAnimOut + '' );
							}, elAnimDelayOutTime );
						}
	
					}
				}
	
				if( !target.classList.contains('not-animated') ) {
					observer.unobserve(entry.target);
				}
			});
		}
	)
}

export function isAnimated(element:HTMLElement) {
	return element.classList.contains(ANIMATE_CLASS_NAME)
};

export function animateElements(rootElement:HTMLElement) {
	if(!intersectionObserver) { return; }

	// We check if the root element is a Vue component
	if(!rootElement.matches && (rootElement as any).$el) {
		rootElement = (rootElement as any).$el;
	}

	// We check the root element
	if(rootElement.matches(SELECTOR) && !isAnimated(rootElement)) {
		intersectionObserver!.observe(rootElement)
	}

	// We check the sub elements
	let elements = [].filter.call(
		rootElement.querySelectorAll(SELECTOR),
		function(element){
			return !isAnimated(element);
		});

	elements.forEach( function(element){
		return intersectionObserver!.observe(element)
	});
}

export function removeElementsAnimation(rootElement:HTMLElement) {
	if(!intersectionObserver) { return; }

	// We check the root element
	if(rootElement.matches(SELECTOR)) {
		intersectionObserver!.unobserve(rootElement)
	}

	// We check the sub elements
	let elements = rootElement.querySelectorAll(SELECTOR);
	elements.forEach( function(element){
		return intersectionObserver!.unobserve(element);
	});
}