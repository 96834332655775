<template>
  <component 
    :is="tag" 
    ref="localizedContentElement" 
    v-if="content != ''" 
    v-html="content">
  </component>
</template>

<style>

</style>

<script lang="ts">
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { ref, defineComponent, watch, onUnmounted, onMounted } from 'vue'

export default defineComponent({
  props: {
    localizedContent : {
      type: [Object,String], 
      required: true
    },
    tag : {
      type: String,
      required: false,
      default: "section"
    }
  },
  setup(props, context) {

const router = useRouter();
    const store = useStore();
    const app = getApp();

    const localizedContentElement = ref(null);
    const content = ref("");
    populateContent();
    
    onMounted(() => {
      addEvents();
    })

    onUnmounted(() => {
      removeEvents();
    })

    function populateContent() {
      // We get the content with correct filters
      content.value = app.getLocalizedText(props.localizedContent, {filters: ['staticPath']});
    }

    function addEvents() {
      if(localizedContentElement.value) {
        // We add the link click event if relative path
        var links:NodeListOf<HTMLAnchorElement> = (localizedContentElement.value as HTMLElement).querySelectorAll('a');
        links.forEach((link) => {
          var href = link.getAttribute("href");
          if(href && href.startsWith("/")) {
            link.addEventListener('click',linkClickHandler);
          }
        })
      }
    }

    function linkClickHandler(evt:Event) {
      evt.preventDefault();
      var link = evt.currentTarget as HTMLAnchorElement;
      var path = link.pathname; // .slice(1)
      router.push(path).catch((err:any) => { console.log(err); });
    }

    function removeEvents() {
      if(localizedContentElement.value) {
        // We remove the link click event
        var links:NodeListOf<HTMLAnchorElement> = (localizedContentElement.value as HTMLElement).querySelectorAll('a');
        links.forEach((link) => {
          link.removeEventListener('click', linkClickHandler);
        })
      }
    }

    watch(() => store.state.languages.currentLanguageCode, (to:any, from:any) => {
      removeEvents();
      populateContent();
      addEvents();
    })

    return {
      content,
      localizedContentElement,
      tag : props.tag
    }
  }
  
})
</script>