<template>
    <div class="services" ref="services">
        <div class="container">
            <div class="mx-auto center heading-block">
                <h3 class="h1">{{ $t('home.services.title') }}</h3>
            </div>

            <div  class="row gutter-50 mb-5 align-items-stretch">
                <div class="col-lg-4">
                    <div class="card d-flex align-items-center flex-column p-4 border-0" style="background-color: #E2E8D8;" @click="onCardClick('creation-site-internet')">
                        
                            <div class="card-body">
                                <div class="d-flex align-items-center mb-4 justify-content-center">
                                    <img src="@public/img/vigneron-online/home/services-creation-site-internet.svg" height="100" :alt="$t('home.services.site-internet.title')" />
                                </div>
                                <h4 class="card-title fw-bolder h4">{{ $t('home.services.site-internet.title') }}</h4>
                                <p class="card-text mb-0 mt-2 fw-light">{{ $t('home.services.site-internet.text') }}</p>
                                <router-link :to="'/'+currentLanguageCode+'/creation-site-internet'" class="button button-border button-rounded button-large">{{ $t('home.services.details-button') }}</router-link>
                            </div>
                        
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="card d-flex align-items-center flex-column p-4 border-0" style="background-color: #C2DFEC;" @click="onCardClick('boutique-en-ligne')">
                        
                            <div class="card-body">
                                <div class="d-flex align-items-center mb-4 justify-content-center">
                                    <img src="@public/img/vigneron-online/home/services-boutique-en-ligne.png" height="100" :alt="$t('home.services.boutique.title')" />
                                </div>
                                <h4 class="card-title fw-bolder h4">{{ $t('home.services.boutique.title') }}</h4>
                                <p class="card-text mb-0 mt-2 fw-light">{{ $t('home.services.boutique.text') }}</p>
                                <router-link :to="'/'+currentLanguageCode+'/boutique-en-ligne'" class="button button-border button-rounded button-large">{{ $t('home.services.details-button') }}</router-link>
                            </div>
                        
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="card d-flex align-items-center flex-column p-4 border-0" style="background-color: #FADCE4" @click="onCardClick('strategie-marketing')">
                        
                            <div class="card-body">
                                <div class="d-flex align-items-center mb-4 justify-content-center">
                                    <img src="@public/img/vigneron-online/home/services-strategie-marketing.svg" height="100" :alt="$t('home.services.marketing.title')" />
                                </div>
                                <h4 class="card-title fw-bolder h4">{{ $t('home.services.marketing.title') }}</h4>
                                <p class="card-text mb-0 mt-2 fw-light">{{ $t('home.services.marketing.text') }}</p>
                                <router-link :to="'/'+currentLanguageCode+'/strategie-marketing'" class="button button-border button-rounded button-large">{{ $t('home.services.details-button') }}</router-link>
                            </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

    .services .card {
        transition: all .25s;
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
        cursor: pointer;
        height:100%;
    }

    .services .card:hover {
        box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
    }

    .services .card:hover a.button {
        background-color: #444;
        color: #FFF;
        border-color: transparent !important;
        text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
    }

    .services .card .card-body {
        display:flex;
        flex-direction: column;
    }

    .services .card .card-body p {
        flex-grow: 1;
    }

    .services .card a.button {
        margin:20px 0px 0px 0px;
        display: block;
        text-align: center;
    }
   
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapGetters } from '@fwk-node-modules/vuex';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { animateElements, removeElementsAnimation } from '../../../theme/canvas-664/canvas.animations';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  components: { 
  },
  // We add name property from store as a name computed
  computed: {
      ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE,
        })
  }
})
export default class Services extends Vue {

    mounted() {
        animateElements(this.$refs.services as HTMLElement)
    }

    beforeDestroy() {
        removeElementsAnimation(this.$refs.services as HTMLElement)
    }

    onCardClick(pageShortName:string) {
        // We display confirmation page
		var languageCode = this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
      	// We redirect to the confirmation page
      	this.$router.push({
            name : pageShortName,
            params : {
                lang : languageCode,
            }
		}).catch(err => {});
	}
    
}
</script>