<template>
    <a 
        ref="button"
        :href="buttonHref" 
        :target="buttonTarget" 
        @click="onButtonClick" 
        :data-animate="isAnimated ? animation.animate:undefined" 
        :data-delay="isAnimated ? animation.delay:undefined" 
        class="button" 
        v-html="(labels.button) ? $cms.utils.getComputedLabel(labels.button) : ''"
    ></a>
</template>

<style>


</style>


<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import type { Ref } from '@fwk-node-modules/vue';
import { animateElements, removeElementsAnimation } from '@root/src/client/theme/canvas-664/canvas.animations';
import { useStore, useCmsModule, useRouter } from '@fwk-client/utils/vue-3-migration';
import { ActionLabels, ActionOptions, useActions } from '@root/src/client/utils/actions';
import { CmsLabel } from '@fwk-client/modules/cms/types';

/** @cmsOptions */
export interface ButtonOptions {
    action:ActionOptions,
    isAnimated?:boolean, // true to enable default animation
    animation?:AnimationOptions
}

interface AnimationOptions {
    animate?:string, //data-animate="fadeInUp" data-delay="400"
    delay?:number
}

/** @cmsLabels */
export interface ButtonLabels {
    /** @cmsType CmsLabel */
  button?:CmsLabel;
  action?:ActionLabels
}


export default defineComponent({
    props: {
        options: Object as PropType<ButtonOptions>,
        labels: {
          type: Object as PropType<ButtonLabels>,
          default: () => { return {} }
        }
    },
    setup(props, context) {
        // We need the router and refs
        const router = useRouter();
        const store = useStore(); 
        const cms = useCmsModule(); 

        const button:Ref<HTMLElement|null> = ref(null);

        const computedOptions:ButtonOptions = {
            isAnimated : false,
            action:{},
            ...props.options,
            animation : {
                animate: 'jello',
                ...(props.options ? props.options.animation : undefined)
            }
        };

        const { actionHref, actionTarget, onActionClick } = useActions();

        const buttonHref = computed(() => {
            return actionHref(computedOptions.action);
        })

        const buttonTarget = computed(() => {
            return actionTarget(computedOptions.action);
        })

        onMounted(() => {
            if(computedOptions.isAnimated) {
                animateElements(button.value as HTMLElement)
            }
        })

        onBeforeUnmount(() => {
             if(computedOptions.isAnimated) {
                removeElementsAnimation(button.value as HTMLElement)
            }
        })

        const onButtonClick = (evt:Event) => {
            onActionClick(evt, computedOptions.action, props.labels.action);
        }

        return {
            button,
            buttonHref,
            buttonTarget,
            onButtonClick,
            isAnimated : computedOptions.isAnimated,
            animation : computedOptions.animation
        }

    }
})
</script>